<template>
  <div class="wid1200 collected-papers-page">
    <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
    <div class="collected-papers-wrap">
      <div class="collected-papers">
        <div class="collected-info">
          <div class="img-collected">
            <img v-if="detail && detail.imgPath" :src="detail.imgPath" alt=""/>
            <img v-else></img>
          </div>
        </div>
        <div class="content-collected">
          <h5>
            <h5 v-html="pageName"></h5>
            <div v-if="detail.cnki==1 || detail.cssci==1 || detail.ami==1 || detail.amiCore==1 || (detail.directoriesDate==1)">

              <!-- <img src="../assets/img/label.png" alt="" /> -->
              <p class="included-database">
                <!-- 被收录数据库： -->
                <span v-if="detail.cssci==1"> CSSCI </span>
                <span v-if="detail.ami==1"> AMI入库 </span>
                <span v-if="detail.amiCore==1"> AMI核心 </span>
                <span v-if="detail.cnki==1"> CNKI </span>
                <span v-if="detail.directoriesDate==1"> CNI名录集刊 </span>


              </p>
            </div>
          </h5>

          <div class="content-collected-wrap">
            <div class="left-collected">
              <div class="iedolType" v-if="detail && detail.author">
                主编：<span>{{ detail.author }}</span>
              </div>

              <div class="com-flex">
                <p v-if="detail && detail.firstDate">
                  创刊时间：<span>{{ detail.firstDate }}</span></p>
                <p v-if="detail && detail.subject">
                  所属学科：<span>{{ detail.subject }}</span></p>
              </div>
              <div class="com-flex">
                <p v-if="detail && detail.publisher">
                  出版单位：<span>{{ detail.publisher }}</span></p>
                <p v-if="detail && detail.cycle">
                  出版周期：<span>{{ detail.cycle }}</span></p>
              </div>
              <div v-if="detail && detail.insNames">
                主办单位：<span>{{ detail.insNames }}</span>
              </div>
              <div class="abstract" v-if="detail && detail.description">
                集刊简介：
                <span
                    v-if="isHiden">{{ detail.description.length > 206 ? detail.description.slice(0, 206) + '...' : detail.description }} </span>
                <span v-else>{{ detail.description }}</span>

                <span style="color: #bd1a2d; float: right; cursor: pointer;" @click="switchHiden"
                      v-if="detail && detail.description && detail.description.length>206">{{ isHiden ? '更多>' : '收起>' }}</span>
              </div>

            </div>

            <div class="right-collected">
              <!-- <div class="er-wrap">
                  <img class="er" src="../assets/img/er.png" alt="" />
                  <p>手机查看集刊</p>
              </div> -->
              <div class="er-wrap">
                <div class="er codeImg" id="codeImg" ref="qrcodeContainer"></div>
                <p>手机查看集刊</p>
              </div>
              <a href="http://www.iedol.cn" target="_blank"><img class="contribution"
                                                                 src="../assets/img/contribution.png" alt=""/></a>
              <img @click="printDeal" class="propaganda" src="../assets/img/propaganda.png" alt=""/>
            </div>
          </div>

        </div>

      </div>
      <div class="collected-msg">
        <ul class="left-msg">
          <li v-if="detail&&detail.startingArticleList&&detail.startingArticleList.length>0"
              :class="{'active':curTab==0}" @click="switchMag(0)">提前在线
          </li>
          <li :class="{'active':curTab==1}" @click="switchMag(1)">刊期</li>
          <li :class="{'active':curTab==2}" @click="switchMag(2)">发刊词</li>
          <li :class="{'active':curTab==3}" @click="switchMag(3)">编辑委员会</li>
          <li :class="{'active':curTab==4}" @click="switchMag(4)">主编介绍</li>
          <li :class="{'active':curTab==5}" @click="switchMag(5)">稿约</li>
          <li :class="{'active':curTab==6}" @click="switchMag(6)">主办单位介绍</li>
          <!--                    <li :class="{'active':curTab==7}" @click="switchMag(7)">英文介绍</li>-->
        </ul>

        <div class="right-msg">
          <ul class="right-msg-info" v-if="curTab==0">
            <!-- <ul class="right-catalog"> -->
            <li v-for="iterm in detail.startingArticleList" :key="iterm.id">

<!--              <div class="bot-wrap">-->
<!--                <div :class="['big-p']"-->
<!--                     @click="goDetail(iterm)" v-if="iterm.searchTitle">-->
<!--                  <p v-if="iterm.openAccessStatus == 1"-->
<!--                     style="padding-top: 3px; box-sizing: border-box; padding-right: 4px;"><img-->
<!--                      src="../assets/img/lock.png" alt="" style="width: 20px"/></p>-->

<!--                  <p v-html="iterm.searchTitle"></p>-->
<!--                </div>-->

<!--                <div :class="['big-p']"-->
<!--                     @click="goDetail(iterm)"-->
<!--                     v-if="(!iterm.searchTitle) && iterm.title">-->
<!--                  <p v-if="iterm.openAccessStatus == 1"-->
<!--                     style="padding-top: 3px; box-sizing: border-box; padding-right: 4px;"><img-->
<!--                      src="../assets/img/lock.png" alt="" style="width: 20px"/></p>-->
<!--                  <p v-html="iterm.title"></p>-->
<!--                </div>-->
<!--                <a :title="iterm.author"-->
<!--                   v-if="iterm.author">{{ iterm.author.length <= 14 ? iterm.author : (iterm.author.slice(0, 14) + '...') }}</a>-->
<!--              </div>-->


              <div v-if="iterm.catalogType == 0">
                <!-- 0:一级标题 1:普通文章 -->

                <h5 :class="[isCatelog? 'small-p' : 'big-p']"
                    @click="goDetail(iterm)" v-if="iterm.searchTitle" v-html="iterm.searchTitle">
                </h5>
                <h5 :class="[isCatelog? 'small-p' : 'big-p']"
                    @click="goDetail(iterm)"
                    v-if="(!iterm.searchTitle) && iterm.title" v-html="iterm.title"></h5>
              </div>
              <div class="bot-wrap" v-if="iterm.catalogType == 1">
                <div :class="[isCatelog? 'small-p' : 'big-p']"
                     @click="goDetail(iterm)" v-if="iterm.searchTitle">
                  <p v-if="iterm.openAccessStatus == 1"
                     style="padding-top: 3px; box-sizing: border-box; padding-right: 4px;"><img
                      src="../assets/img/lock.png" alt="" style="width: 20px"/></p>

                  <p v-html="iterm.searchTitle"></p>
                </div>

                <div :class="[isCatelog? 'small-p' : 'big-p']"
                     @click="goDetail(iterm)"
                     v-if="(!iterm.searchTitle) && iterm.title">
                  <p v-if="iterm.openAccessStatus == 1"
                     style="padding-top: 3px; box-sizing: border-box; padding-right: 4px;"><img
                      src="../assets/img/lock.png" alt="" style="width: 20px"/></p>
                  <p v-html="iterm.title"></p>
                </div>
                <a :title="iterm.author"
                   v-if="iterm.author">{{ iterm.author.length <= 14 ? iterm.author : (iterm.author.slice(0, 14) + '...') }}</a>
              </div>

            </li>

          </ul>
          <ul class="issue" v-if="curTab==1 && detail && detail.bookList">
            <li v-for="iterm in detail.bookList" :key="iterm.id" @click="golink(iterm.id,iterm.collectnum)">
              <span>第 {{ iterm.collectnum }} 期</span><span>({{ iterm.releaseDate | changeTime }})</span></li>
          </ul>

          <div class="" v-if="curTab==2 && detail && detail.introduction" v-html="detail.introduction"></div>
          <div class="committee_style" v-if="curTab==3 && detail">
            <el-row>
              <el-col :span="12">
                <div v-show="detail.editors">
                  <p style="font-weight: 700;color:#4db6be">编辑委员会</p>
                  <div v-html="detail.editors"></div>
                </div>
              </el-col>
              <el-col :span="12">
                <div v-show="detail.experts" class="experts_committtee">
                  <p style="font-weight: 700;color:#4db6be">专家委员会</p>
                  <div v-html="detail.experts"></div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-if="curTab==4 && detail && detail.authorList">
            <div style="font-size: 14px; color: #333; margin-bottom: 20px;" v-for="(item, index) in detail.authorList"
                 :key="index">
              <!-- <p>
                  <span style="display: block; font-weight: 700;">{{item.name}} ：</span>
              </p> -->

              <p v-html="item.detail"></p>
            </div>
            <!-- <p v-for="(item, index) in detail.authorList" :key="index" style="margin-bottom: 20px;"><span
                    style="font-weight: 700;">{{item.name}} ：</span>{{item.description}}</p> -->
          </div>
          <div v-if="curTab==5 && detail && detail.notice" v-html="detail.notice"></div>
          <div v-if="curTab==6 && detail && detail.researchInsList">
            <div v-for="(item,index) in detail.researchInsList" :key="index" style="margin-bottom: 20px;"><span
                style="font-weight: 700; margin-bottom: 2px; font-size: 16px; display: block;">{{ item.name }} ：</span>
              <div v-if="item && item.description" v-html="item.description"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="hot-article-wrap">
      <div class="com-title">
        <div>
          <img src="../assets/img/articleicon.png" alt="" style="width: 25px; height: 29px;"/>
          <h5>热门文章</h5>
        </div>
        <!-- <div>
                <span>更多<img src="../assets/img/more.png" alt="" /></span>
            </div> -->
      </div>
      <ul class="hot-article">
        <li v-for="item in hotList" :key="item.id" @click="goDetail(item)">
          <p>{{ item.searchTitle ? item.searchTitle : item.title }}</p><span>{{ item.author }}</span>
        </li>
      </ul>
    </div>

    <div class="print_box" id="printBox">
      <div class="page_box PageNext">
        <div class="page_break clear-fix">
          <div class="fl">
            <h5 class="title" v-if="detail && detail.name">{{ detail.name }}</h5>
            <p class="resouce" v-if="detail && detail.insNames">{{ detail.insNames }}</p>
            <p class="mian" v-if="detail && detail.author">主编：{{ detail.author }}</p>
            <p class="blue"><span>*</span>突破单一学科的局限和研究领域的禁区，鼓励在政治、经济、社会、文化和环境等领域进行跨学科和交叉学科的研究
            </p>
            <p class="blue"><span>*</span>对中国基层社会和微观问题使用经验和实证手段，在多个单独领域、低于进行切实调查和深入研究
            </p>
            <p class="blue"><span>*</span>鼓励和支持学术新人的研究成果发布 </p>
            <p class="abs" v-if="detail && detail.description">{{ detail.description }}</p>
          </div>
          <div class="fr">
            <img class="print_img" v-if="detail && detail.imgPath" :src="detail.imgPath" alt="">
            <p class="print_num" v-if="detail && detail.cycle">{{ detail.cycle }}</p>
            <p class="print_num" v-if="detail && detail.cnki && (detail.cnki==1)"><span
                v-if="detail.cnki==1">CNKI</span>来源集刊</p>
            <p class="print_num" v-if="detail && detail.ami && (detail.ami==1)">
                <span v-if="detail.ami==1">AMI入库</span>来源集刊
            </p>
            <p class="print_num" v-if="detail && detail.amiCore && (detail.amiCore==1)">
                <span v-if="detail.amiCore==1">AMI核心</span>来源集刊
            </p>
            <p class="print_num" v-if="detail && detail.cssci && (detail.cssci==1)"><span
                v-if="detail.cssci==1">CSSCI</span>来源集刊
            </p>
            <p class="blue mrt60">集刊全文数据库在线阅读</p>
            <a class="blue" href="http://www.jikan.com.cn">www.jikan.com.cn</a>
          </div>
          <div class="clear"></div>
        </div>
        <div class="iedolCode">
          <div class="codeImg fl" id="codeImg1" ref="qrcodeContainer1"></div>
          <img src="../assets/img/logo_jk.png" class="logo_jk" alt="">
        </div>
      </div>
      <div class="page_break page_last clear-fix">
        <div class="fl">
          <div class="iedol_logo">
            <img src="../assets/img/iedol_logo.png" alt="">
            <p>在线投稿</p>
          </div>
          <div class="new_list">
            <h4>最新文章</h4>
            <ul>
              <li v-for="(item, index) in newList" :key="index">
                <h5>{{ item.title }}</h5>
                <h6>{{ item.searchTitle }}</h6>
                <p>{{ item.author }}</p>
              </li>
            </ul>
          </div>
          <img class="ssap_logo" src="../assets/img/ssap_logo.png" alt="">

        </div>
        <div class="fr">
          <h5>稿约</h5>
          <div class="content_gy" v-if="detail && detail.notice" v-html="detail.notice"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import QRCode from 'qrcodejs2'

export default {
  components: {BreadCrumb},
  data() {
    return {
      isHiden: true,
      pageName: '集刊主页',
      pN: '',
      detail: {},
      hotList: [],
      newList: [],
      curTab: 0
    }
  },
  filters: {
    changeTime(data) {
      var arr = data.split('-')
      if (arr.length > 1) {
        return arr[0] + '-' + arr[1]
      } else {
        return arr[0]
      }
    }
  },
  methods: {
    init() {
    },
    switchHiden() {
      this.isHiden = !this.isHiden
    },
    changeDate(data) {
      var arr = data.split('-')
      if (arr.length > 1) {
        return arr[0] + '-' + arr[1]
      } else {
        return arr[0]
      }
    },
    golink(id, num) {
      this.$router.push({path: '/collectionDetail/c', query: {id: id, pN: encodeURI(this.pageName)}})
    },
    goDetail(item) {
      this.$router.push({path: '/aD/a', query: {id: item.id, pN: encodeURI(this.pageName)}})
      // title: item.title,
    },
    switchMag(num) {
      this.curTab = num
    },
    getDetail(id) {
      var _this = this
      _this.$http
          .get('/admin/api/cp/detail', {
            params: {
              idOrName: id
            }
          })
          .then(res => {
            _this.detail = res.data.data
            _this.pageName = _this.detail.name
            if (_this.detail.startingArticleList && _this.detail.startingArticleList.length > 0) {
              _this.curTab = 0
            } else {
              _this.curTab = 1
            }
            if (_this.detail.firstDate) {
              _this.detail.firstDate = _this.changeDate(_this.detail.firstDate)
            } else {
              _this.detail.firstDate = ''
            }
            // this.$nextTick(function () {
            //     var aa = _this.$refs.test.clientHeight;
            //     if(aa<136){
            //         _this.noopenbtn = false
            //     }
            // })
            this.getHotList(_this.detail.id) //this.$route.query.id 453
            this.getNewList(_this.detail.id) //this.$route.query.id 453
            this.showQRCode(_this.$refs.qrcodeContainer, _this.detail.id, 92, 92)
            this.showQRCode(_this.$refs.qrcodeContainer1, _this.detail.id, 150, 150)
          })
    },
    getHotList(id) {
      var _this = this
      _this.$http
          .get('/admin/api/article/hot', {
            params: {
              collectedPapersId: id
            }
          })
          .then(res => {
            if (res.data.code == 0) {
              _this.hotList = res.data.data
            }
          })
    },
    getNewList(id) {
      var _this = this
      _this.$http
          .get('/admin/api/cp/excellentArticle', {
            params: {
              id: id
            }
          })
          .then(res => {
            if (res.data.code == 0) {
              _this.newList = res.data.data
            }
          })
    },
    showQRCode(ele, id, w, h) {
      var codeUrl = ''
      if (this.$route.query.pN) {
        var curUrl = window.location.href
        var i = curUrl.indexOf("&pN")
        codeUrl = curUrl.substring(0, i)
      } else {
        codeUrl = window.location.href
      }


      this.$nextTick(() => {
        var qrcode = new QRCode(ele, {
          text: codeUrl,
          width: w,
          height: h,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
        qrcode.clear() // 清除代码
        qrcode.makeCode(codeUrl)
      })
    },
    //打印
    printDeal() {
      // console.log(1111);
      // let routeData = this.$router.resolve({
      //     name: "iedolprint",
      //     params:{id:this.$route.params.id}
      // });
      // window.open(routeData.href, '_blank');
      // this.$router.push({name:'iedolprint',params:{id:this.$route.params.id}})
      let myCanvas = document.getElementById('codeImg').getElementsByTagName('img')
      this.iedolCode = myCanvas[0].src
      var printBox = document.getElementById('printBox')
      //拿到打印的区域的html内容
      var newContent = printBox.innerHTML
      //将旧的页面储存起来，当打印完成后返给给页面。
      var oldContent = document.body.innerHTML
      //赋值给body
      document.body.innerHTML = newContent
      //执行window.print打印功能
      window.print()
      // 重新加载页面，以刷新数据。以防打印完之后，页面不能操作的问题
      window.location.reload()
      document.body.innerHTML = oldContent
      return false
    }
  },
  created() {

    if (this.$route.query.pN) {
      this.pN = decodeURI(this.$route.query.pN)
    } else {
      this.pN = ''
    }

    var curId
    if (this.$route.query.id) {
      curId = this.$route.query.id
    } else {
      curId = sessionStorage.getItem('idCollection')
    }

    this.getDetail(curId)
    // this.init()
  }
}
</script>

<style lang="scss" scoped>
.collected-papers-page {
  .collected-papers-wrap {
    border: 1px solid #f5f5f5;
    box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 30px;
    margin-bottom: 32px;

    .collected-papers {
      display: flex;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 30px;

      .collected-info {
        padding-bottom: 28px;

        .img-collected {
          img {
            display: block;
            width: 250px;
            height: 356px;
            margin-right: 20px;
            box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
          }
        }
      }

      .content-collected {
        h5 {
          font-size: 20px;
          line-height: 22px;
          font-weight: bold;
          display: flex;
          // justify-content: space-between;
          div {
            display: flex;
            align-items: center;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            margin-left: 10px;
            // img {
            //     width: 17px;
            //     height: 17px;
            //     margin-right: 8px;
            // }
            .included-database {
              span {
                float: left;
                height: 22px;
                margin-left: 10px;
                display: block;
                font-size: 12px;
                color: #BD1A2D;
                padding: 0 10px;
                line-height: 22px;
                border: 0.8px solid #A91728;
                box-sizing: border-box;
                border-radius: 10px;
                margin-bottom: 5px;
                white-space: nowrap;
              }
            }
          }
        }

        .content-collected-wrap {
          display: flex;

          .left-collected {
            width: 706px;

            div {
              margin-top: 19px;
              line-height: 17px;
              font-size: 16px;
              color: #666;

              p {
                font-size: 16px;
                color: #666;
              }

              span {
                font-size: 16px;
                color: #333;
              }

              p:first-child {
                width: 400px;
              }
            }

            .com-flex {
              display: flex;
            }

            .abstract {
              width: 706px;
              color: #666;
              line-height: 35px;
              margin-top: 14px;
              word-break: break-all;

              span {
                color: #666;
              }

              // text-overflow: -o-ellipsis-lastline;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // -webkit-line-clamp: 5;
              // line-clamp: 5;
              // -webkit-box-orient: vertical;
            }
          }

          .right-collected {
            width: 116px;
            margin-left: 46px;
            padding-top: 24px;

            .er-wrap {
              width: 116px;
              height: 116px;
              background: url(../assets/img/erwrap.png);
              background-size: 100% 100%;
              padding-top: 10px;
              box-sizing: border-box;

              .er {
                width: 96px;
                height: 96px;
                margin-bottom: 4px;
                margin-left: 10px;
              }

              p {
                text-align: center;
                color: #bd1a2d;
                font-size: 12px;
                line-height: 12px;
              }
            }

            img.contribution {
              width: 116px;
              height: 58px;
              margin: 26px 0 14px;
            }

            img.propaganda {
              width: 116px;
              height: 58px;
            }
          }
        }
      }
    }

    .collected-msg {
      display: flex;
      justify-content: space-between;

      ul.left-msg {
        margin-right: 20px;

        li {
          width: 250px;
          height: 56px;
          line-height: 56px;
          text-align: center;
          box-sizing: border-box;
          border: 1px solid #e6e6e6;
          font-size: 16px;
          cursor: pointer;
        }

        li.active {
          color: #fff;
          background: #bd1a2d;
          border: 1px solid #bd1a2d;
        }
      }

      .right-msg {
        width: 868px;
        min-height: 392px;
        // background: #bd1a2d;
        ul.issue {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 128px;
            height: 82px;
            margin-right: 20px;
            margin-bottom: 20px;
            background: #dabc85;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            padding-top: 18px;
            cursor: pointer;

            span {
              display: block;
              color: #fff;
              font-size: 16px;
            }
          }

          li:nth-child(6n + 6) {
            margin-right: 0;
          }
        }

        ul.right-msg-info {
          h5 {
            font-size: 16px;
            color: #333;
            line-height: 38px;
            font-weight: bold;
            margin: 20px 0 8px;
          }
          div.bot-wrap {
            display: flex;

            align-items: center;
            position: relative;
            line-height: 38px;
            justify-content: space-between;
            background: url(../assets/img/border.png);
            background-position: 6% 49%;
            background-repeat: repeat-x;
            background-size: 1% 1px;
            margin-bottom: 12px;

            div {
              display: flex;
              padding-left: 6px;
              font-size: 16px;
              color: #333;
              background: #fff;
              padding-right: 7px;
              cursor: pointer;

              p {
                font-size: 16px;
                color: #333;
              }
            }

            .big-p {
              max-width: 525px;
              line-height: 26px;
            }

            a {
              font-size: 16px;
              color: #666;
              background: #fff;
              padding-left: 5px;
              display: block;
              line-height: 26px;
              cursor: auto;
            }
          }

          div.bot-wrap::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: -4px;
            top: 12px;
          }
        }
      }
    }
  }

  .hot-article-wrap {
    width: 1200px;
    margin-bottom: 30px;
    padding: 10px 30px;
    box-sizing: border-box;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);

    .hot-article {
      margin-bottom: 13px;
      display: flex;
      flex-wrap: wrap;
      background: url(../assets/img/border1.png);
      background-size: 1px 50%;
      background-repeat: repeat-y;
      background-position: 50% 0%;
      margin-top: 20px;

      li {
        width: 569px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        line-height: 19px;
        margin-bottom: 20px;

        p {
          font-size: 18px;
          padding-left: 10px;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #333;

          span {
            color: #666;
          }
        }

        p::before {
          content: '';
          width: 4px;
          height: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 8px;
          border-radius: 50%;
          background: #ffac13;
        }

        span {
          width: 164px;
          text-align: left;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      li:nth-child(2n + 1) {
        p {
          width: 385px;
        }
      }

      li:nth-child(2n + 2) {
        p {
          width: 358px;
        }
      }

      li:nth-child(2n + 2) {
        box-sizing: border-box;
        padding-left: 35px;
      }

      li:nth-child(2n + 2)::before {
        left: 39px;
      }

      li:last-child {
        margin-bottom: 0;
      }

      li:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
}

.print_box {
  display: none;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  @page {
    size: 8.5in 11.5in;
    margin: 1.5cm 2cm;
  }
  .PageNext {
    page-break-after: always;
  }
  .print_box {
    /*padding: 30mm 18mm;*/
    /*display: none;*/
    color: #000;
    font-size: 14pt;
    background: #333000;
  }
  .page_break .fl {
    width: 320pt;
  }
  .page_last .fl {
    width: 210pt;
  }
  .page_break .title {
    font-size: 25.5pt;
    line-height: 40pt;
    margin-bottom: 20pt;
    font-weight: bold;
  }
  .resouce {
    font-size: 13.5pt;
    line-height: 20pt;
    margin-bottom: 20pt;
    font-weight: bold;
  }
  .mian {
    line-height: 20pt;
    margin-bottom: 20pt;
  }
  .blue {
    padding-left: 20pt;
    line-height: 30pt;
    margin-bottom: 10pt;
    font-size: 14.2pt;
    color: #2987cb !important;
  }
  .blue > span {
    float: left;
    margin-left: -20pt;
    line-height: 30pt;
    color: #2987cb !important;
  }
  .abs {
    width: 320pt;
    font-size: 13.8pt;
    margin-top: 30pt;
    line-height: 28pt;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    flex-direction: column;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    // -webkit-box-orient: vertical;
  }
  .page_break .fr {
    width: 150pt;
  }
  .page_last .fr {
    width: 276pt;
  }
  .print_img {
    width: 144pt;
    height: 210pt;
    border: 1pt solid #000;
    box-shadow: 5px 5px 5px #666 !important;
    margin-bottom: 20pt;
  }
  .print_num {
    text-align: center;
    margin-bottom: 15pt;
  }
  .fr .blue {
    text-align: center;
  }
  .fr .blue.mrt60 {
    margin-top: 50pt;
  }
  .page_break {
    margin-bottom: 30pt;
  }
  .iedolCode {
    width: 100%;
    height: 118pt;
  }
  .logo_jk {
    float: right;
    margin-top: 36pt;
    width: 57pt;
    height: 72pt;
  }
  .iedol_logo img {
    width: 100pt;
    height: 100pt;
    margin-bottom: 10pt;
  }
  .iedol_logo p {
    width: 105pt;
    text-align: center;
    letter-spacing: 4pt;
    font-size: 14pt;
  }
  .page_last h5 {
    text-align: center;
    font-size: 15pt;
    line-height: 30pt;
    font-weight: bold;
    letter-spacing: 5pt;
    margin-bottom: 30pt;
  }
  .new_list {
    width: 200pt;
    margin-top: 10pt;
    padding: 0 10pt;
    padding-top: 5pt;
    border: 3pt solid #909a83;
  }
  .new_list > h4 {
    text-align: center;
    font-size: 13.5pt;
    line-height: 30pt;
    font-weight: bold;
    margin-bottom: 10pt;
    letter-spacing: 7.5pt;
    color: #000;
  }
  .new_list li h5 {
    letter-spacing: 0;
    text-align: left;
    font-size: 13.5pt;
    line-height: 20pt;
    width: 180pt;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
    margin-bottom: 5pt;
    font-weight: bold;
    color: #000;
  }
  .new_list li h6 {
    font-size: 10pt;
    line-height: 20pt;
    margin-bottom: 5pt;
    color: #000;
    width: 180pt;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
  }
  .new_list li p {
    font-size: 10pt;
    line-height: 20pt;
    margin-bottom: 5pt;
    font-style: italic;
    color: #333;
    width: 180pt;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
  }
  .ssap_logo {
    width: 70pt;
    height: 70pt;
    margin-top: 10pt;
  }
  .content_gy {
    height: 640pt;
    overflow: hidden; /*超出部分隐藏*/
  }
  .content_gy > > > p {
    font-size: 9pt;
    line-height: 21pt;
    margin-bottom: 14pt;
    color: #6e6e6e !important;
  }
}
</style>
